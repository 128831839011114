import React from 'react'
import './style.scss'
export default props => {
  return (
    <button
      {...props}
      onClick={e => props.onClick(e)}
      className={`dh-button  ${props.pulse ? 'dh-animate-pulse' : ''}`}
    >
      {props.children}
    </button>
  )
}
