import React from 'react'
import moment from 'moment-timezone'
import SelectPicker from '../SelectPicker'
import DhDateRangePicker from '../DateRangePicker'
import DhDatePicker from '../DatePicker'
import './style.scss'
import hoc from '../../components/hoc'
import TimePicker from '../TimePicker'
import Button from '../Button'
import Separator from './Separator'
import { WEB_SEARCH_LINK, API_GET_LOCATIONS } from '../../../config'
// import { withTranslation } from '../../components/locales'

const END_DATE = 'endDate'

class SearchBar extends React.PureComponent {
  static defaultProps = {
    locations: [],
    location_id: 1,
    province: '',
  }
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
    this.handleOutsideClick = this.handleOutsideClick.bind(this)
    this._AdjustTime = this._AdjustTime.bind(this)
    this.onReturnDateChange = this.onReturnDateChange.bind(this)
    this.state = {
      locationName:
        props.locations.length !== 0
          ? this._InitLocation(props.locationId).name
          : '',
      locationID: Number(this.props.locationId),
      pickupTime: this.props.booking_begin
        ? this._InitTime(moment(this.props.booking_begin).format('HH:mm'))
        : this._InitTime('10:00'),
      returnTime: this.props.booking_end
        ? this._InitTime(moment(this.props.booking_end).format('HH:mm'))
        : this._InitTime('10:00'),
      focused: false,
      startDate: this.props.booking_begin
        ? moment(this.props.booking_begin)
        : this.props.isInterBrand
        ? moment().add(7, 'days')
        : moment().add(1, 'days'),
      endDate: this.props.booking_end
        ? moment(this.props.booking_end)
        : this.props.isInterBrand
        ? moment().add(9, 'days')
        : moment().add(3, 'days'),
      locations: this.props.locations,
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      // NOTE: Auto select location
      !this.props.isHomepage &&
      prevProps.locations.length === 0 &&
      this.props.locations.length !== 0
    ) {
      this.setState({
        locationName: this._InitLocation(this.props.locationId).name,
      })
    }
    if (
      this.props.vendorsLocations &&
      this.props.vendorsLocations.length !== 0
    ) {
      this.setState({
        locations: this.props.vendorsLocations,
      })
    }
    if (this.props.locations && !this.props.isInterBrand) {
      this.setState({
        locations: this.props.locations,
      })
    }
  }

  handleClick() {
    if (this.state.focused) {
      document.addEventListener('click', this.handleOutsideClick, false)
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false)
    }
  }

  handleOutsideClick(e) {
    if (this.SelectPicker && this.SelectPicker.contains(e.target)) {
      return
    }
    this.handleClick()
    this.setState({
      endDateFocused: false,
      focused: false,
    })
  }

  _next_available_time(dateTime = new Date()) {
    var hours =
      dateTime.getMinutes() > 30
        ? dateTime.getHours() + 3
        : dateTime.getHours() + 2
    hours = hours > 23 ? hours - 24 : hours
    var minutes = dateTime.getMinutes() > 30 ? '00' : '30'
    var time = hours + ':' + minutes
    return time
  }

  _createTime(limit = 24, item = [], i = 0) {
    if (item.length < limit * 2) {
      return this._createTime(
        limit,
        item.concat([
          { value: `${i > 9 ? i : '0' + i}:00`, label: `${i}:00` },
          { value: `${i > 9 ? i : '0' + i}:30`, label: `${i}:30` },
        ]),
        i + 1,
      )
    } else {
      return item
    }
  }
  _generateTime() {
    const times = this._createTime()
      .splice(12, this._createTime().length)
      .concat(this._createTime().splice(0, 12))
      .filter(time => time.value !== '00:00')
    return times
  }
  _generateGroupTime(seed) {
    const times = seed.map(item => ({ name: item.label, value: item.value }))
    return [
      {
        data: times.slice(0, 24),
        label: 'ช่วงเวลาแนะนำ',
      },
      {
        data: times.slice(24, times.length),
        label: 'อื่นๆ',
      },
    ]
  }
  _InitTime(expect) {
    return this._createTime().filter(item => expect === item.value)[0]
  }

  _getReturnTime() {
    return this._InitTime(this._AdjustTime(4))
  }

  _AdjustTime(number) {
    const Time = this.state.pickupTime
    const hours = Time.value.split(':')[0]
    const min = Time.value.split(':')[1]
    const calHours = (Number(hours) + number) % 24
    const HoursText = calHours > 9 ? calHours : `0${calHours}`
    return `${HoursText}:${min}`
  }

  _InitLocation(locationId) {
    let selected = { name: '', id: null }
    this.props.locations.forEach(item => {
      item.locations.forEach(location => {
        if (location.id === Number(locationId)) {
          selected = { name: location.name.th, value: location.id }
          return false
        }
        return true
      })
    })
    return selected
  }
  _onSubmit(event) {
    event.preventDefault()
    if (
      this.state.returnTime &&
      this.state.pickupTime &&
      this.state.startDate &&
      this.state.endDate &&
      this.state.locationName
    ) {
      let params = {
        booking_begin: `${moment(this.state.startDate).format('YYYY-MM-DD')} ${
          this.state.pickupTime.value
        }`,
        booking_end: `${moment(this.state.endDate).format('YYYY-MM-DD')} ${
          this.state.returnTime.value
        }`,
        location_id: this.state.locationID,
      }
      if (this.props.brandId) {
        params = { ...params, dealers: this.props.brandId }
      }
      const parsed = Object.keys(params).reduce(
        (p, c) => (p += `${c}=${params[c]}&`),
        '?',
      )
      window.location = `${WEB_SEARCH_LINK}/${parsed}`
    } else {
      alert('โปรดระบุข้อมูลให้ครบถ้วน')
    }
  }

  getHoursDiff(start, end) {
    const startMoment = moment(`1970-01-01 ${start}`, 'YYYY-MM-DD HH:mm')
    const endMoment = moment(`1970-01-01 ${end}`, 'YYYY-MM-DD HH:mm')
    var duration = moment.duration(endMoment.diff(startMoment))
    var hours = duration.asHours()
    return hours
  }

  onPickupDateChange = value => {
    this.setState(
      {
        startDate: value,
        endDate: value
          .startOf('days')
          .isAfter(this.state.endDate.startOf('days'))
          ? moment(value).add(1, 'days')
          : this.state.endDate,
        startDateFocused: false,
      },
      this.afterChangeDateAndTime,
    )
  }
  onReturnDateChange = ({
    endDate: endDateValue,
    startDate: startDateValue,
  }) => {
    document.body.classList.remove('noscroll')
    if (endDateValue) {
      return this.setState(
        {
          endDate: endDateValue,
          endDateFocused: false,
        },
        this.afterChangeDateAndTime,
      )
    }
    return this.setState(
      {
        endDateFocused: false,
        startDate: startDateValue,
        endDate: startDateValue,
      },
      this.afterChangeDateAndTime,
    )
  }

  afterChangeDateAndTime = () => {
    const { startDate, endDate } = this.state
    if (endDate.isBefore(startDate)) {
      return this.setState(
        {
          startDate: endDate,
          endDate,
        },
        this.afterChangeDateAndTime,
      )
    }
    if (startDate.isSame(endDate)) {
      this.setState({ returnTime: this.returnTimeAvailable[0] })
    }
  }
  onPickupTimeChange = value => {
    return this.setState({ pickupTime: value }, this.afterChangeDateAndTime)
  }

  get pickupTimeAvailable() {
    const times = this._generateTime()
    if (
      moment()
        .startOf('day')
        .isBefore(this.state.startDate.startOf('day'))
    ) {
      return times
    }
    const timeStr = moment().format('HH:mm')
    const target = moment(`1970-01-01 ${timeStr}`, 'YYYY-MM-DD HH:mm')
    const filteredTime = times.filter(time => {
      const t = moment(`1970-01-01 ${time.value}`, 'YYYY-MM-DD HH:mm')
      return t.isSameOrAfter(target)
    })
    return filteredTime
  }

  get returnTimeAvailable() {
    const times = this._generateTime()
    if (
      this.state.startDate
        .startOf('day')
        .isSame(this.state.endDate.startOf('day'))
    ) {
      const timeStr = this.state.pickupTime.value
      const target = moment(`1970-01-01 ${timeStr}`, 'YYYY-MM-DD HH:mm').add(
        4,
        'hours',
      )
      const filteredTime = times.filter(time => {
        const t = moment(`1970-01-01 ${time.value}`, 'YYYY-MM-DD HH:mm')
        return t.isAfter(target)
      })
      return filteredTime
    }
    return times
  }

  moveArrayElement(array, fromIndex, toIndex) {
    var element = array[fromIndex]
    array.splice(fromIndex, 1)
    array.splice(toIndex, 0, element)
  }

  formatLocationList(locations, cityId) {
    if (locations.length === 0 || locations === undefined) return []
    const cityList = locations
      .sort((a, b) => a.position - b.position)
      .map(item => ({
        ...item,
        data: item.locations.map(l => ({ ...l, name: l.name.th })),
        name: item.name.th,
      }))
    if (!this.props.isHomepage && !this.props.isInterBrand) {
      this.moveArrayElement(
        cityList,
        cityList.findIndex(city => city.id === Number(cityId)),
        0,
      )
      return cityList
    } else {
      return cityList
    }
  }

  render() {
    const { province, cityId, isHomepage, isInterBrand } = this.props
    const { locations } = this.state

    return (
      <div className="search-box">
        <div
          className="location-picker"
          ref={component => {
            this.SelectPicker = component
          }}
        >
          {/* <h4 className="text-center d-block pt-2 search-box__header">เช็คราคาและรถว่าง</h4> */}
          <p
            className="location-picker__header"
            onClick={e => {
              this.setState({ focused: true }, () => this.handleClick(e))
            }}
          >
            {isHomepage || isInterBrand
              ? 'เช็คราคาและรถว่าง'
              : `เช็คราคาและรถว่างใน${province}`}
          </p>
          <div title="เลือกจุดหมาย เพื่อเริ่มต้นจองรถเช่าออนไลน์ ที่นี่เลย!">
            <div>
              <SelectPicker
                onChange={event => {
                  this.setState({ locationName: event.target.value })
                }}
                searchable={true}
                value={this.state.locationName}
                focused={this.state.focused}
                placeholder="เลือกจุดหมาย"
                mobilePlaceholder="เลือกจุดหมาย"
                onSelect={({ name, id }) => {
                  this.setState({
                    locationName: name,
                    locationID: id,
                    focused: false,
                  })
                  if (this.state.startDate == null)
                    this.setState({ startDateFocused: true })
                  document.body.classList.remove('noscroll')
                }}
                data={this.formatLocationList(locations, cityId)}
                handleClick={this.handleClick}
                onClearText={event => this.setState({ locationName: '' })}
                onFocus={event => {
                  this.setState({ focused: true })
                }}
                onBlur={event => this.setState({ focused: false })}
                openIndex={isHomepage ? -1 : 0}
              />
            </div>
          </div>
        </div>
        <div className="datetime-picker">
          <p className="datetime-picker__header">วันและเวลารับรถ-คืนรถ</p>
          <div className="datetime-picker__box">
            <div className="datetime-picker__box--left">
              <DhDatePicker
                numberOfMonths={1}
                enableOutsideDays
                placeholder="เลือกวันรับรถ"
                mobilePlaceholder="เลือกวันรับรถ"
                focused={this.state.startDateFocused}
                date={this.state.startDate}
                onDateChange={this.onPickupDateChange}
                onMobileClose={startDate => {
                  this.setState({ startDateFocused: false })
                  document.body.classList.remove('noscroll')
                  if (
                    this.state.pickupTime == null &&
                    this.state.startDate !== null
                  )
                    this.setState({ pickupTimeFocused: true })
                }}
                onMobileDateChange={startDateValue => {
                  document.body.classList.remove('noscroll')
                  this.onPickupDateChange(startDateValue)
                }}
                isDayHighlighted={() => false}
                isDayBlocked={item => {
                  return moment()
                    .startOf('day')
                    .isAfter(item)
                }}
                onOutsideClick={e => {
                  this.setState({
                    startDateFocused: false,
                  })
                }}
                onClick={e => {
                  this.setState({
                    startDateFocused: true,
                  })
                }}
              />

              <TimePicker
                mobileWidth={600}
                value={this.state.pickupTime}
                onValueChange={this.onPickupTimeChange}
                options={this.pickupTimeAvailable}
                mobileOptions={this._generateGroupTime(
                  this.pickupTimeAvailable,
                )}
                focused={this.state.pickupTimeFocused}
                onClick={event => this.setState({ pickupTimeFocused: true })}
                onClose={event => {
                  this.setState({ pickupTimeFocused: false })
                  document.body.classList.remove('noscroll')
                }}
                placeholder="เลือกเวลา"
                mobilePlaceholder="เลือกเวลารับรถ"
              />
            </div>

            <div className="datetime-picker__box--middle">
              <Separator />
            </div>
            <div className="datetime-picker__box--right">
              <DhDateRangePicker
                numberOfMonths={1}
                enableOutsideDays
                focused={this.state.endDateFocused}
                placeholder="เลือกวันคืนรถ"
                mobilePlaceholder="เลือกวันคืนรถ"
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onDatesChange={this.onReturnDateChange}
                onMobileDatesChange={this.onReturnDateChange}
                isDayBlocked={item => {
                  return moment()
                    .startOf('day')
                    .isAfter(item)
                }}
                onMobileClose={() => {
                  this.setState({
                    endDateFocused: false,
                    returnTimeFocused: true,
                  })
                }}
                focusedInput={END_DATE}
                onOutsideClick={e => this.setState({ endDateFocused: false })}
                onClick={e => this.setState({ endDateFocused: true })}
              />

              <TimePicker
                mobileWidth={600}
                value={this.state.returnTime}
                onValueChange={value => {
                  this.setState({ returnTime: value })
                  document.body.classList.remove('noscroll')
                }}
                options={this.returnTimeAvailable}
                mobileOptions={this._generateGroupTime(
                  this.returnTimeAvailable,
                )}
                focused={this.state.returnTimeFocused}
                onClick={event => this.setState({ returnTimeFocused: true })}
                onClose={event => {
                  this.setState({ returnTimeFocused: false })
                }}
                placeholder="เลือกเวลา"
                mobilePlaceholder="เลือกเวลาคืนรถ"
              />
            </div>
          </div>
        </div>
        <div className="search-button">
          <Button id="mainBtn" pulse="true" onClick={this._onSubmit.bind(this)}>
            ค้นหารถเช่าราคาดีที่สุด
          </Button>
        </div>
      </div>
    )
  }
}

export default hoc({
  url: API_GET_LOCATIONS,
  field: 'locations',
})(SearchBar)
