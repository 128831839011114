import React, { Component } from 'react'
import './style.scss'

class Modal extends Component {
  componentDidMount() {
    window && require('disable-scroll').default.on()
  }
  componentWillUnmount() {
    window && require('disable-scroll').default.off()
  }

  render() {
    const { children, className, active } = this.props
    return active ? (
      <div className="dh-modal-wrapper">
        <div className={`dh-modal ${className}`}>
          <i
            className="flaticon-cancel close-button"
            aria-hidden="true"
            onClick={event => {
              event.preventDefault()
              window && require('disable-scroll').default.off()
              this.props.onClose(event)
            }}
          />
          {children}
        </div>
      </div>
    ) : (
      ''
    )
  }
}

export default Modal
