import React, { Component } from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import MediaQuery from 'react-responsive'
import SearchBox from '../../legacy-components/SearchBar'
import StepCard from './step-card'
import './style.scss'

const GOOGLE_FORM_BOOKED_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSd9lm8gKQJinoJ28ZTBk177qTyvOS7BuJZpkdmJhaMakqYyrw/viewform?usp=sf_link'
export default class LuckyDraw extends Component {
  state = {
    active: 'no-book',
  }
  renderLine = style => {
    return (
      <MediaQuery maxWidth={768}>
        {matches => {
          if (matches) {
            return (
              <div className="mobile-line">
                <div className="main-line" />
              </div>
            )
          } else {
            return (
              <img
                className="desktop-line"
                style={style}
                src="/assets/lucky-draw-step-line.png"
                alt="split-line"
              />
            )
          }
        }}
      </MediaQuery>
    )
  }

  get luckyDrawImg() {
    return (
      <MediaQuery maxWidth={768}>
        {matches => {
          if (matches) {
            return (
              <img
                className="lucky-header__img"
                src="/assets/drivehub-reward-mobile.png"
                alt="banner"
              />
            )
          } else {
            return (
              <img
                className="lucky-header__img"
                src="/assets/drivehub-reward.png"
                alt="banner"
              />
            )
          }
        }}
      </MediaQuery>
    )
  }
  stepButtonClick = step => {
    this.setState({ active: step })
    if (step === 'no-book') {
      document
        .getElementById('no-book-section')
        .scrollIntoView({ behavior: 'smooth' })
    }
    if (step === 'booked') {
      window.open(GOOGLE_FORM_BOOKED_URL, '_blank')
    }
  }
  get stepOptions() {
    const { active } = this.state
    return (
      <div className="col-12 step-options">
        <p className="step-options__title">วิธีลงทะเบียนเพื่อรับสิทธิ์</p>
        <div className="step-options__button">
          <div
            className={`step-button step-button--no-book ${
              active === 'no-book' ? 'step-button--active' : ''
            }`}
            onClick={() => this.stepButtonClick('no-book')}
          >
            สำหรับลูกค้าที่ยังไม่ได้ทำการจอง
          </div>
          <div
            className={`step-button step-button--booked  ${
              active === 'booked' ? 'step-button--active' : ''
            }`}
            onClick={() => this.stepButtonClick('booked')}
          >
            สำหรับลูกค้าที่ทำการจองไปแล้ว
          </div>
        </div>
      </div>
    )
  }
  render() {
    const { prefix } = this.props
    return (
      <div className="lucky-draw">
        <Layout prefix={prefix} theme="black">
          <SEO title={'เช่ารถก็ถูกน้ำมันก็ฟรี กับ Drivehub Reward'} />
          <div className="container">
            <div className="col-12 col-md-10 container--lucky-draw">
              <div className="lucky-header">
                <p className="lucky-header__text">
                  จองรถเช่ากับ Drivehub วันนี้ ลุ้นรับบัตรเติมน้ำมัน 1200฿ จำนวน
                  40 รางวัล
                </p>
                {this.luckyDrawImg}
              </div>
              <div className="lucky-content">
                <div className="lucky-content__header">
                  <p className="content-header-text">
                    เช่ารถก็ถูกน้ำมันก็ฟรี กับ Drivehub Reward
                  </p>
                  <p>
                    ลุ้นรับบัตรเติมน้ำมันบางจาก มูลค่า จำนวน 40 รางวัล
                    เพียงจองรถเช่ากับ Drivehub และลงทะเบียน
                  </p>
                  {this.stepOptions}
                </div>
                <div className="col-12 lucky-content__step">
                  <div className="step__no-book" id="no-book-section">
                    <div className="no-book-header">
                      <p style={{ fontSize: 16 }} className="font-weight-bold">
                        วิธีลงทะเบียนเพื่อรับสิทธิ์
                      </p>
                      <p className="no-book-title">
                        สำหรับลูกค้าที่ยังไม่ได้ทำการจอง
                      </p>
                    </div>

                    {luckyDrawStep.map((step, i) => {
                      return (
                        <StepCard
                          key={i}
                          className={
                            step.stepNumber === 2
                              ? 'lucky-step-item--right'
                              : ''
                          }
                          stepNumber={step.stepNumber}
                          imgSrc={step.imgSrc}
                          imgAlt={step.imgAlt}
                          stepTitle={step.stepTitle}
                          stepContent={step.stepContent}
                          renderLine={
                            step.stepNumber === 2
                              ? this.renderLine({ transform: 'scaleX(-1)' })
                              : step.stepNumber === luckyDrawStep.length
                              ? ''
                              : this.renderLine()
                          }
                        />
                      )
                    })}
                  </div>
                </div>
              </div>
              <div className="lucky-search-box">
                <p className="step-text">เริ่มต้นค้นหาและจองรถเช่า!</p>
                <div
                  className="col-md-12 px-2 py-2 p-md-0"
                  style={{ backgroundColor: '#EDEFF2', maxWidth: 500 }}
                >
                  <SearchBox isHomepage={true} />
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    )
  }
}

const luckyDrawStep = [
  {
    stepNumber: 1,
    imgSrc: '/assets/lucky-draw-step-1.png',
    imgAlt: 'ค้นหารถเช่า',
    stepTitle: 'ค้นหารถเช่า',
    stepContent:
      'เริ่มการค้นหาโดยระบุสถานที่รับรถ วันและเวลารับและคืนรถรถเช่าที่สามารถทำการจองได้จะปรากฏอบู่บนหน้าผลลัพท์การค้นหา',
  },
  {
    stepNumber: 2,
    imgSrc: '/assets/lucky-draw-step-2.png',
    imgAlt: 'เลือกและจองรถเช่า',
    stepTitle: 'เลือกและจองรถเช่า',
    stepContent:
      'หลังจากที่คุณเลือกรถคันที่ต้องการแล้วคุณจำเป็นต้องกรอกข้อมูลการติดต่อและข้อมูลขับขี่',
  },
  {
    stepNumber: 3,
    imgSrc: '/assets/lucky-draw-step-3.png',
    imgAlt: 'ลงทะเบียนเพื่อรับสิทธิ์',
    stepTitle: 'ลงทะเบียนเพื่อรับสิทธิ์',
    stepContent:
      'หลังจากที่คุณทำการจองแล้ว คุณจะเข้าสู่หน้ายืนยันการจองให้กดปุ่ม ‘คลิกเพื่อลงทะเบียน’เพื่อกรอกข้อมูลในการรับสิทธิ์',
  },
]
