import React, { Component } from 'react'

export default class Separator extends Component {
  render() {
    return (
      <div className="calendar--separator">
        <div>
          <svg height="60" width="23">
            <line x1="0" y1="0" x2="23" y2="30" />
            <line x1="0" y1="60" x2="23" y2="30" />
          </svg>
        </div>
      </div>
    )
  }
}
