import React from 'react'
import axios from 'axios'

class Fetch extends React.Component {
  state = {
    isLoading: true,
    data: {},
  }
  componentDidMount() {
    axios
      .get(this.props.url)
      .then(response => response.data)
      .then(response =>
        this.setState({
          isLoading: false,
          data: response,
        }),
      )
      .catch(error => console.log(error))
  }
  render() {
    if (this.state.isLoading) {
      // Enhance Loading
      return this.props.children() // <div>LOADING</div>
    }
    return this.props.children(this.state.data)
  }
}

// export default HOC

const hoc = ({ url, field }) => Component => {
  class klass extends React.Component {
    render() {
      return (
        <Fetch url={url}>
          {data => {
            return <Component {...{ [field]: data }} {...this.props} />
          }}
        </Fetch>
      )
    }
  }
  return klass
}

export default hoc
