import React from 'react'
import { DayPickerRangeController } from 'react-dates'
import moment from 'moment-timezone'
import Media from 'react-responsive'
import Modal from '../Modal'
import Button from '../Button'
import 'react-dates/lib/css/_datepicker.css'
import './style.scss'
moment.locale('th')
export default props => {
  const {
    placeholder,
    focused,
    onClick,
    onDatesChange,
    onMobileDatesChange,
    onMobileOpen,
    startDate,
    endDate,
    onMobileClose,
    mobilePlaceholder,
    onOutsideClick,
    ...otherProps
  } = props
  return (
    <div className="dh-DatePicker">
      <div onClick={props.onClick}>
        <Media query={`(min-device-width: ${props.mobileWidth ? props.mobileWidth : '992'}px)`}>
          <i
            className={`${props.icon ? `${props.icon}` : 'flaticon-calendar'}`}
            aria-hidden="true"
          />
          <input
            readOnly
            type="text"
            placeholder={props.placeholder}
            value={props.endDate === undefined ? '' : moment(props.endDate).format('DD MMM YYYY')}
          />
        </Media>
        <Media query={`(max-device-width: ${props.mobileWidth ? props.mobileWidth : '992'}px)`}>
          <i
            className={`${props.icon ? `${props.icon}` : 'flaticon-calendar'}`}
            aria-hidden="true"
          />
          <div className="input">
            {props.endDate ? moment(props.endDate).format('DD MMM YYYY') : props.placeholder}
          </div>
        </Media>
      </div>
      {focused ? (
        <div>
          <Media query={`(min-device-width: ${props.mobileWidth ? props.mobileWidth : '992'}px)`}>
            <div style={{ position: 'absolute', zIndex: '99', marginTop: '1px' }}>
              <DayPickerRangeController
                {...otherProps}
                onOutsideClick={onOutsideClick}
                onDatesChange={onDatesChange}
                startDate={startDate}
                minimumNights={0}
                endDate={endDate}
              />
            </div>
          </Media>
          <Media query={`(max-device-width: ${props.mobileWidth ? props.mobileWidth : '992'}px)`}>
            <Modal active={focused} onClose={onMobileClose}>
              <div className="header">
                <h3 className="title">{mobilePlaceholder}</h3>
              </div>
              <div className="mobile-single-datepicker-container">
                <h3>
                  {startDate === undefined
                    ? 'โปรดรถบุวันรับรถ'
                    : moment(startDate).format('DD MMM YYYY')}{' '}
                  - {endDate === null ? 'โปรดระบุวันคืนรถ' : moment(endDate).format('DD MMM YYYY')}
                </h3>
                <br />
                <DayPickerRangeController
                  {...otherProps}
                  onDatesChange={onMobileDatesChange}
                  startDate={startDate}
                  minimumNights={0}
                  endDate={endDate}
                />
                <div className="bottom-section">
                  <Button onClick={event => onDatesChange({ startDate, endDate })}>ตกลง</Button>
                </div>
              </div>
            </Modal>
          </Media>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
