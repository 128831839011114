import React from 'react'
import './style.scss'
import Media from 'react-responsive'
import Modal from '../Modal'
import { LoadingWheel } from '../../components/loading'

export default class SelectPicker extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      active: props.openIndex,
      activeLocation: -1,
    }
  }
  _search = text => {
    return text == null
      ? this.props.data
      : this.props.data.filter(
          item =>
            item.name.toLowerCase().search(text.toLowerCase()) !== -1 ||
            item.data.filter(
              item => item.name.toLowerCase().search(text.toLowerCase()) !== -1,
            ).length > 0,
        )
  }

  _renderPopup = () => {
    if (this.props.focused)
      return (
        <div>
          <Media
            query={`(min-device-width: ${
              this.props.mobileWidth ? this.props.mobileWidth : '768'
            }px)`}
          >
            {this._renderDesktopList()}
          </Media>
          <Media
            query={`(max-device-width: ${
              this.props.mobileWidth ? this.props.mobileWidth : '768'
            }px)`}
          >
            {matches => (matches ? this._renderMobileList() : '')}
          </Media>
        </div>
      )
  }

  _renderMobileList = e => {
    return (
      <Modal
        active={this.props.focused}
        className="mobile"
        onClose={this.props.onBlur}
      >
        <div className="mobile-input">
          <h3 className="title">{this.props.mobilePlaceholder}</h3>
          {this.props.searchable ? (
            <div className="dh-input">
              <i
                className={'flaticon-close close'}
                aria-hidden="true"
                onClick={this.props.onClearText}
              />
              <i
                className={`${
                  this.props.icon ? `${this.props.icon}` : 'flaticon-search'
                }`}
                aria-hidden="true"
              />
              <input
                type="text"
                placeholder={this.props.placeholder}
                value={this.props.value}
                onChange={this.props.onChange}
              />
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="mobile-content-container">{this._renderList()}</div>
      </Modal>
    )
  }

  _renderDesktopList = () => {
    return <div className="desktop">{this._renderList()}</div>
  }

  _renderList = () => {
    // const dataList = this._search(this.props.value) // Filter get only selected city,
    const dataList = this.props.data // Return all city.

    let colWidth = {
      width: '100%',
      maxHeight: '370px',
    }
    const { active } = this.state
    const { noExpand } = this.props

    return (
      <div
        className="dh-location-container"
        ref={el => (this.ScrollContainer = el)}
        style={colWidth}
      >
        {dataList.length > 0 ? (
          dataList.map((item, index) => {
            return item.data.length === 0 ? (
              ''
            ) : (
              <div
                key={index}
                className="dh-item"
                // onClick={e => {
                //   const maxScroll = this.ScrollContainer.scrollHeight - 500 - 46
                //   if (this.ScrollContainer.scrollTop >= maxScroll) {
                //     setTimeout(() => {
                //       this.ScrollContainer.scrollTop = this.ScrollContainer.scrollHeight
                //     }, 1000)
                //   }
                // }}
              >
                <div
                  className="dh-title"
                  onClick={() => {
                    this.setState({ active: active === index ? -1 : index })
                  }}
                >
                  {item.name}
                  {active === index || noExpand ? (
                    <i className="flaticon-minus" />
                  ) : (
                    <i className="flaticon-plus" />
                  )}
                </div>
                <div
                  className={`dh-list-container ${
                    active === index || dataList.length === 1 || noExpand
                      ? 'active'
                      : ''
                  }`}
                >
                  {item.data.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={`${
                          item.id === this.state.activeLocation
                            ? 'active-location'
                            : ''
                        }`}
                        style={{ cursor: 'pointer' }}
                        onClick={event => {
                          event.preventDefault()
                          this.props.onSelect(item)
                          this.setState({ activeLocation: item.id })
                        }}
                      >
                        {/* eslint-disable-next-line */}
                        <a href="#" className="dh-name">
                          {item.id === this.state.activeLocation && (
                            <i className="flaticon-check" />
                          )}
                          {item.name}
                        </a>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })
        ) : (
          <p className="not-found">
            {this.props.notFoundText ? this.props.notFoundText : 'ไม่พบข้อมูล'}
          </p>
        )}
      </div>
    )
  }
  render() {
    return (
      <div className="dh-SelectPicker">
        {this.props.value ? (
          <i
            className={'flaticon-close close'}
            aria-hidden="true"
            onClick={() => {
              this.props.onClearText()
              this.setState({ activeLocation: -1 })
            }}
          />
        ) : (
          ''
        )}

        <i
          className={`${
            this.props.icon ? `${this.props.icon}` : 'flaticon-search'
          }`}
          aria-hidden="true"
          onClick={e => this.props.onFocus(e)}
        />
        {this.props.data.length !== 0 ? (
          <React.Fragment>
            <Media
              query={`(min-device-width: ${
                this.props.mobileWidth ? this.props.mobileWidth : '992'
              }px)`}
            >
              <input
                type="text"
                placeholder={this.props.placeholder}
                value={this.props.value}
                onFocus={event => {
                  this.props.onFocus(event)
                }}
                onClick={this.props.handleClick}
                onChange={this.props.onChange}
              />
            </Media>
            <Media
              query={`(max-device-width: ${
                this.props.mobileWidth ? this.props.mobileWidth : '992'
              }px)`}
            >
              <div
                className="input"
                onClick={event => {
                  this.props.onFocus(event)
                }}
              >
                {this.props.value ? this.props.value : this.props.placeholder}
              </div>
            </Media>
            {this._renderPopup()}
          </React.Fragment>
        ) : (
          <div style={{ paddingTop: 10 }}>
            <LoadingWheel />
          </div>
        )}
      </div>
    )
  }
}
