import React from 'react'
import Media from 'react-responsive'
import Modal from '../Modal'
import Button from '../Button'
import { DayPickerSingleDateController } from 'react-dates'
import moment from 'moment-timezone'
import 'react-dates/lib/css/_datepicker.css'
import './style.scss'

export default props => {
  const {
    placeholder,
    focused,
    onClick,
    onDateChange,
    onMobileDateChange,
    mobilePlaceholder,
    onMobileClose,
    onOutsideClick,
    ...otherProps
  } = props
  return (
    <div className="dh-DatePicker">
      <div onClick={onClick}>
        <Media query={`(min-device-width: ${props.mobileWidth ? props.mobileWidth : '992'}px)`}>
          <i
            className={`${props.icon ? `${props.icon}` : 'flaticon-calendar'}`}
            aria-hidden="true"
          />
          <input
            readOnly
            placeholder={placeholder}
            value={props.date === undefined ? '' : moment(props.date).format('DD MMM YYYY')}
          />
        </Media>
        <Media query={`(max-device-width: ${props.mobileWidth ? props.mobileWidth : '992'}px)`}>
          <i
            className={`${props.icon ? `${props.icon}` : 'flaticon-calendar'}`}
            aria-hidden="true"
          />
          <div className="input">
            {props.date ? moment(props.date).format('DD MMM YYYY') : props.placeholder}
          </div>
        </Media>
      </div>
      {focused ? (
        <div>
          <Media query={`(min-device-width: ${props.mobileWidth ? props.mobileWidth : '992'}px)`}>
            <div style={{ position: 'absolute', zIndex: '99', marginTop: '1px' }}>
              <DayPickerSingleDateController
                {...otherProps}
                onOutsideClick={onOutsideClick}
                onDateChange={onDateChange}
              />
            </div>
          </Media>
          <Media query={`(max-device-width: ${props.mobileWidth ? props.mobileWidth : '992'}px)`}>
            <Modal active={focused} onClose={props.onMobileClose}>
              <div className="header">
                <h3 className="title">{mobilePlaceholder}</h3>
              </div>
              <div className="mobile-single-datepicker-container">
                <h3>
                  {props.date === undefined
                    ? 'โปรดรถบุวันรับรถ'
                    : moment(props.date).format('DD MMM YYYY')}
                </h3>
                <br />
                <DayPickerSingleDateController
                  {...otherProps}
                  onDateChange={props.onMobileDateChange}
                />
                <div className="bottom-section">
                  <Button onClick={onMobileClose}>ตกลง</Button>
                </div>
              </div>
            </Modal>
          </Media>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}
