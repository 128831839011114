import React, { Component } from 'react'
import MediaQuery from 'react-responsive'
import './style.scss'

export default class StepCard extends Component {
  render() {
    const {
			className,
			stepNumber,
      imgSrc,
      imgAlt,
      stepTitle,
      stepContent,
      renderLine
    } = this.props
    return (
      <MediaQuery maxWidth={768}>
        {matches => {
          if (matches) {
            return (
              <div className={`lucky-step-item ${className}`}>
                <div className="col-12 col-md-6 text-center">
                  <img className="step-img" src={imgSrc} alt={imgAlt} />
                </div>
                <div className="col-12 col-md-6 step-detail">
                  <div className="step-detail__number">{stepNumber}</div>
                  <p className="step-detail__title">
                    {stepTitle}
                    <span>
                      {stepContent}
                      {stepNumber === 3 && (
                        <img
                          className="register-img"
                          src='/assets/lucky-draw-register.png'
                          alt='คลิกเพื่อลงทะเบียน'
                        />
                      )}
                    </span>
                  </p>
                </div>
                {renderLine}
              </div>
            )
          } else {
            return (
              <React.Fragment>
                <div className={`lucky-step-item ${className}`}>
                  <div className="col-12 col-md-6 text-center">
                    <img className="step-img" src={imgSrc} alt={imgAlt} />
                  </div>
                  <div className="col-12 col-md-6 step-detail">
                    <div className="step-detail__number">{stepNumber}</div>
                    <p className="step-detail__title">
                      {stepTitle}
                      <span>
                        {stepContent}
                        {stepNumber === 3 && (
                          <img
                            className="register-img"
                            src='/assets/lucky-draw-register.png'
                            alt='คลิกเพื่อลงทะเบียน'
                          />
                      )}
                        </span>
                    </p>
                  </div>
                </div>
                {renderLine}
              </React.Fragment>
            )
          }
        }}
      </MediaQuery>
    )
  }
}
