import React from 'react'
import Media from 'react-responsive'
import SelectPicker from '../SelectPicker'
import { SimpleSelect } from 'react-selectize'
import 'react-selectize/dist/index.css'
import './style.scss'

export default class TimePicker extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      pickupTime: '',
    }
  }

  render() {
    return (
      <div className="dh-time-picker">
        <div className="time-picker__box" onClick={this.props.onClick}>
          <i className="flaticon-clock-circular-outline" />
          <Media
            query={`(min-device-width: ${
              this.props.mobileWidth ? this.props.mobileWidth : '992'
            }px)`}
          >
            <SimpleSelect
              tether={true}
              options={this.props.options}
              {...this.props}
            />
          </Media>
          <Media
            query={`(max-device-width: ${
              this.props.mobileWidth ? this.props.mobileWidth : '992'
            }px)`}
          >
            <div className="Timepicker-input">
              {this.props.value
                ? this.props.value.value
                : this.props.placeholder}
            </div>
          </Media>
        </div>
        {this.props.focused ? (
          <Media
            query={`(max-device-width: ${
              this.props.mobileWidth ? this.props.mobileWidth : '992'
            }px)`}
          >
            <SelectPicker
              value={this.props.value ? this.props.value.name : ''}
              focused={this.props.focused}
              placeholder={this.props.placeholder}
              mobilePlaceholder={this.props.mobilePlaceholder}
              onSelect={({ name, value }) => {
                this.props.onValueChange({ label: name, value: value })
                this.props.onClose()
              }}
              data={this.props.mobileOptions}
              handleClick={this.handleClick}
              onFocus={event => {
                this.setState({ focused: true })
              }}
              onBlur={this.props.onClose}
              noExpand={true}
            />
          </Media>
        ) : (
          ''
        )}
      </div>
    )
  }
}
